<template>
  <div id="no-document-found-component">
    <form-a-company-not-found
      v-if="slide.layout_sub_type === 'form a company'"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />
    <generic-not-found v-else :slide="slide" />
  </div>
</template>

<script>

export default {
  name: 'Completed',
  components: {
    FormACompanyNotFound:  () => import('@/components/StagelineV2/slides/document/NotFound/FormACompanyNotFound'),
    GenericNotFound:       () => import('@/components/StagelineV2/slides/document/NotFound/GenericNotFound'),
  },
  props: {
    slide: null,
  },
}
</script>

<style scoped lang="scss">
#no-document-found-component {
  max-width: 56.25em;
}
</style>
