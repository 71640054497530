var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "no-document-found-component" } },
    [
      _vm.slide.layout_sub_type === "form a company"
        ? _c("form-a-company-not-found", {
            attrs: { slide: _vm.slide },
            on: {
              "next-slide": function ($event) {
                return _vm.$emit("next-slide")
              },
            },
          })
        : _c("generic-not-found", { attrs: { slide: _vm.slide } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }